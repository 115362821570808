import React from 'react'
import { Helmet } from "react-helmet"
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import BackgroundImage from 'gatsby-background-image'
import GoogleReviews from '../components/google_reviews_slider'
import Copyright from '../components/copyright'
import FormContact from '../components/form_contact'
import Img from "gatsby-image"
import Partners from '../components/partners'

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=Oswald&display=swap" rel="stylesheet" />
            </Helmet>
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <div className="layout">
                <BackgroundImage fluid={entry.frontmatter.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                    <div className="title__gradient">
                        <div className="layout__container">
                            <div className="layout__wide title center">
                                <h1>{entry.frontmatter.heading}</h1>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            <div className="layout grey">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb">
                            <p><Link to='/'>Home</Link> &nbsp;&rsaquo;&nbsp; <strong>{entry.frontmatter.title}</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="grid grid__2-1 grid__gap--48 text">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                <FormContact />
                            </div>
                            <div className="aside">
                                <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d820050.583568589!2d174.29512276269827!3d-36.59453889790366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d3a416e22e255%3A0x8605dbdb402159c0!2sAbbey%20Road%20Painters!5e0!3m2!1sen!2snz!4v1604885549672!5m2!1sen!2snz" width="100%" height="480" frameborder="0" allowfullscreen="" aria-hidden="false"></iframe>
                                <figure className="master_painter">
                                    <a href="https://www.masterpainters.co.nz/" title="Master Painters" rel="nofollow noopener"><Img fixed={data.master_painter.childImageSharp.fixed} alt='Master Painter' /></a>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GoogleReviews />
            <Partners />
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
        master_painter: file(relativePath: { eq: "MP_Logo.jpg" }) {
            childImageSharp {
                fixed(width: 200) {
                    ...GatsbyImageSharpFixed
                  }
            }
        }
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
                title
				page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`